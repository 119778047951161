var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mail-config-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onTestMailSend.apply(null, arguments)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "p-4 md:p-8" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "mt-3",
                attrs: {
                  label: _vm.$t("general.to"),
                  error: _vm.emailError,
                  variant: "horizontal",
                  required: ""
                }
              },
              [
                _c("sw-input", {
                  ref: "to",
                  attrs: { invalid: _vm.$v.formData.to.$error, type: "text" },
                  on: {
                    input: function($event) {
                      return _vm.$v.formData.to.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.to,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "to", $$v)
                    },
                    expression: "formData.to"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-3",
                attrs: {
                  label: _vm.$t("general.subject"),
                  error: _vm.subjectError,
                  variant: "horizontal",
                  required: ""
                }
              },
              [
                _c("sw-input", {
                  attrs: {
                    invalid: _vm.$v.formData.subject.$error,
                    type: "text"
                  },
                  on: {
                    input: function($event) {
                      return _vm.$v.formData.subject.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.subject,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "subject", $$v)
                    },
                    expression: "formData.subject"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-3",
                attrs: {
                  label: _vm.$t("general.message"),
                  error: _vm.messageError,
                  variant: "horizontal",
                  required: ""
                }
              },
              [
                _c("sw-textarea", {
                  attrs: {
                    invalid: _vm.$v.formData.message.$error,
                    rows: "4",
                    cols: "50"
                  },
                  on: {
                    input: function($event) {
                      return _vm.$v.formData.message.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.message,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "message", $$v)
                    },
                    expression: "formData.message"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-0 flex justify-end p-4 border-t border-gray-200 border-solid"
          },
          [
            _c(
              "sw-button",
              {
                staticClass: "mr-3",
                attrs: { variant: "primary-outline" },
                on: { click: _vm.closeTaxModal }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                attrs: {
                  loading: _vm.isLoading,
                  variant: "primary",
                  type: "submit"
                }
              },
              [
                !_vm.isLoading
                  ? _c("paper-airplane-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(
                      !_vm.isEdit
                        ? _vm.$t("general.send")
                        : _vm.$t("general.update")
                    ) +
                    "\n      "
                )
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }