var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sw-wizard-step",
    {
      attrs: {
        title: _vm.$t("wizard.preferences"),
        description: _vm.$t("wizard.preferences_desc")
      }
    },
    [
      _vm.isFetching
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.next.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mb-4 md:grid-cols-2 md:mb-6"
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$t("wizard.currency"),
                        error: _vm.currencyError,
                        required: ""
                      }
                    },
                    [
                      _c("sw-select", {
                        class: { error: _vm.$v.settingData.currency.$error },
                        attrs: {
                          options: _vm.currencies,
                          "custom-label": _vm.currencyNameWithCode,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t(
                            "settings.currencies.select_currency"
                          ),
                          "track-by": "id",
                          label: "name"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$v.settingData.currency.$touch()
                          }
                        },
                        model: {
                          value: _vm.settingData.currency,
                          callback: function($$v) {
                            _vm.$set(_vm.settingData, "currency", $$v)
                          },
                          expression: "settingData.currency"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$t("settings.preferences.default_language"),
                        error: _vm.languageError,
                        required: ""
                      }
                    },
                    [
                      _c("sw-select", {
                        class: { error: _vm.$v.settingData.language.$error },
                        attrs: {
                          options: _vm.languages,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t(
                            "settings.preferences.select_language"
                          ),
                          label: "name"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$v.settingData.language.$touch()
                          }
                        },
                        model: {
                          value: _vm.settingData.language,
                          callback: function($$v) {
                            _vm.$set(_vm.settingData, "language", $$v)
                          },
                          expression: "settingData.language"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mb-4 md:grid-cols-2 md:mb-6"
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$t("wizard.date_format"),
                        error: _vm.dateFormatError,
                        required: ""
                      }
                    },
                    [
                      _c("sw-select", {
                        class: { error: _vm.$v.settingData.dateFormat.$error },
                        attrs: {
                          options: _vm.dateFormats,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t(
                            "settings.preferences.select_date_format"
                          ),
                          label: "display_date"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$v.settingData.dateFormat.$touch()
                          }
                        },
                        model: {
                          value: _vm.settingData.dateFormat,
                          callback: function($$v) {
                            _vm.$set(_vm.settingData, "dateFormat", $$v)
                          },
                          expression: "settingData.dateFormat"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$t("wizard.time_zone"),
                        error: _vm.timeZoneError,
                        required: ""
                      }
                    },
                    [
                      _c("sw-select", {
                        class: { error: _vm.$v.settingData.timeZone.$error },
                        attrs: {
                          options: _vm.timeZones,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t(
                            "settings.preferences.select_time_zone"
                          ),
                          label: "key"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$v.settingData.timeZone.$touch()
                          }
                        },
                        model: {
                          value: _vm.settingData.timeZone,
                          callback: function($$v) {
                            _vm.$set(_vm.settingData, "timeZone", $$v)
                          },
                          expression: "settingData.timeZone"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid grid-cols-1 gap-4 mb-6 md:grid-cols-2" },
                [
                  _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$t("wizard.fiscal_year"),
                        error: _vm.fiscalYearError,
                        required: ""
                      }
                    },
                    [
                      _c("sw-select", {
                        class: { error: _vm.$v.settingData.fiscalYear.$error },
                        attrs: {
                          options: _vm.fiscalYears,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t(
                            "settings.preferences.select_financial_year"
                          ),
                          label: "key"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$v.settingData.fiscalYear.$touch()
                          }
                        },
                        model: {
                          value: _vm.settingData.fiscalYear,
                          callback: function($$v) {
                            _vm.$set(_vm.settingData, "fiscalYear", $$v)
                          },
                          expression: "settingData.fiscalYear"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    variant: "primary",
                    type: "submit"
                  }
                },
                [
                  !_vm.isLoading
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("wizard.save_cont")) +
                      "\n      "
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }