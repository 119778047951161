var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-page",
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("invoices.title") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { title: _vm.$t("general.home"), to: "dashboard" }
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$tc("invoices.invoice", 2),
                  to: "#",
                  active: ""
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalInvoices,
                      expression: "totalInvoices"
                    }
                  ],
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1"
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "ml-4",
                  attrs: {
                    "tag-name": "router-link",
                    to: "/admin/invoices/create",
                    size: "lg",
                    variant: "primary"
                  }
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("invoices.new_invoice")) +
                      "\n      "
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters"
                }
              ],
              staticClass: "relative grid grid-flow-col grid-rows"
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: { label: _vm.$tc("customers.customer", 1) }
                },
                [
                  _c("base-customer-select", {
                    ref: "customerSelect",
                    on: {
                      select: _vm.onSelectCustomer,
                      deselect: _vm.clearCustomerSearch
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2 xl:mx-8",
                  attrs: { label: _vm.$t("invoices.status") }
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.status,
                      "group-select": false,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t("general.select_a_status"),
                      "allow-empty": false,
                      "group-values": "options",
                      "group-label": "label",
                      "track-by": "name",
                      label: "name"
                    },
                    on: {
                      remove: function($event) {
                        return _vm.clearStatusSearch()
                      },
                      select: _vm.setActiveTab
                    },
                    model: {
                      value: _vm.filters.status,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "status", $$v)
                      },
                      expression: "filters.status"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: { label: _vm.$t("general.from") }
                },
                [
                  _c("base-date-picker", {
                    attrs: {
                      "calendar-button": true,
                      "calendar-button-icon": "calendar"
                    },
                    model: {
                      value: _vm.filters.from_date,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "from_date", $$v)
                      },
                      expression: "filters.from_date"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "hidden w-8 h-0 mx-4 border border-gray-400 border-solid xl:block",
                staticStyle: { "margin-top": "3.5rem" }
              }),
              _vm._v(" "),
              _c(
                "sw-input-group",
                { staticClass: "mt-2", attrs: { label: _vm.$t("general.to") } },
                [
                  _c("base-date-picker", {
                    attrs: {
                      "calendar-button": true,
                      "calendar-button-icon": "calendar"
                    },
                    model: {
                      value: _vm.filters.to_date,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "to_date", $$v)
                      },
                      expression: "filters.to_date"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2 xl:ml-8",
                  attrs: { label: _vm.$t("invoices.invoice_number") }
                },
                [
                  _c(
                    "sw-input",
                    {
                      model: {
                        value: _vm.filters.invoice_number,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "invoice_number", $$v)
                        },
                        expression: "filters.invoice_number"
                      }
                    },
                    [
                      _c("hashtag-icon", {
                        staticClass: "h-5 ml-1 text-gray-500",
                        attrs: { slot: "leftIcon" },
                        slot: "leftIcon"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter }
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen"
            }
          ],
          attrs: {
            title: _vm.$t("invoices.no_invoices"),
            description: _vm.$t("invoices.list_of_invoices")
          }
        },
        [
          _c("moon-walker-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              attrs: {
                slot: "actions",
                "tag-name": "router-link",
                to: "/admin/invoices/create",
                size: "lg",
                variant: "primary-outline"
              },
              slot: "actions"
            },
            [
              _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
              _vm._v(
                "\n      " + _vm._s(_vm.$t("invoices.new_invoice")) + "\n    "
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen"
            }
          ],
          staticClass: "relative"
        },
        [
          _c(
            "div",
            { staticClass: "relative mt-5" },
            [
              _c(
                "p",
                {
                  staticClass: "absolute right-0 m-0 text-sm",
                  staticStyle: { top: "50px" }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.showing")) + ": "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.invoices.length))]),
                  _vm._v("\n\n        " + _vm._s(_vm.$t("general.of")) + " "),
                  _c("b", [_vm._v(_vm._s(_vm.totalInvoices))])
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-tabs",
                {
                  attrs: { "active-tab": _vm.activeTab },
                  on: { update: _vm.setStatusFilter }
                },
                [
                  _c("sw-tab-item", {
                    attrs: { title: _vm.$t("general.due"), filter: "DUE" }
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: { title: _vm.$t("general.draft"), filter: "DRAFT" }
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: { title: _vm.$t("general.all"), filter: "" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-transition",
                { attrs: { type: "fade" } },
                [
                  _vm.selectedInvoices.length
                    ? _c(
                        "sw-dropdown",
                        {
                          staticClass: "absolute float-right",
                          staticStyle: { "margin-top": "-35px" }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "flex block text-sm font-medium cursor-pointer select-none text-primary-400",
                              attrs: { slot: "activator" },
                              slot: "activator"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.actions")) +
                                  "\n            "
                              ),
                              _c("chevron-down-icon", { staticClass: "h-5" })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            { on: { click: _vm.removeMultipleInvoices } },
                            [
                              _c("trash-icon", {
                                staticClass: "h-5 mr-3 text-gray-600"
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.delete")) +
                                  "\n          "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.invoices && _vm.invoices.length,
                  expression: "invoices && invoices.length"
                }
              ],
              staticClass:
                "absolute z-10 items-center pl-4 mt-2 select-none md:mt-12"
            },
            [
              _c("sw-checkbox", {
                staticClass: "hidden md:inline",
                attrs: { variant: "primary", size: "sm" },
                on: { change: _vm.selectAllInvoices },
                model: {
                  value: _vm.selectAllFieldStatus,
                  callback: function($$v) {
                    _vm.selectAllFieldStatus = $$v
                  },
                  expression: "selectAllFieldStatus"
                }
              }),
              _vm._v(" "),
              _c("sw-checkbox", {
                staticClass: "md:hidden",
                attrs: {
                  label: _vm.$t("general.select_all"),
                  variant: "primary",
                  size: "sm"
                },
                on: { change: _vm.selectAllInvoices },
                model: {
                  value: _vm.selectAllFieldStatus,
                  callback: function($$v) {
                    _vm.selectAllFieldStatus = $$v
                  },
                  expression: "selectAllFieldStatus"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table"
              }
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "no-click"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return _c(
                        "div",
                        { staticClass: "relative block" },
                        [
                          _c("sw-checkbox", {
                            attrs: {
                              id: row.id,
                              value: row.id,
                              variant: "primary",
                              size: "sm"
                            },
                            model: {
                              value: _vm.selectField,
                              callback: function($$v) {
                                _vm.selectField = $$v
                              },
                              expression: "selectField"
                            }
                          })
                        ],
                        1
                      )
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.date"),
                  "sort-as": "invoice_date",
                  show: "formattedInvoiceDate"
                }
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.number"),
                  show: "invoice_number"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("invoices.number")))]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            attrs: {
                              to: { path: "invoices/" + row.id + "/view" }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.invoice_number) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.customer"),
                  width: "20%",
                  show: "name"
                }
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.status"),
                  "sort-as": "status"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("invoices.status")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-badge",
                          {
                            attrs: {
                              "bg-color": _vm.$utils.getBadgeStatusColor(
                                row.status
                              ).bgColor,
                              color: _vm.$utils.getBadgeStatusColor(row.status)
                                .color
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$utils.getStatusTranslation(
                                    row.status.replace("_", " ")
                                  )
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.paid_status"),
                  "sort-as": "paid_status"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("invoices.paid_status")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-badge",
                          {
                            attrs: {
                              "bg-color": _vm.$utils.getBadgeStatusColor(
                                row.status
                              ).bgColor,
                              color: _vm.$utils.getBadgeStatusColor(row.status)
                                .color
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$utils.getStatusTranslation(
                                    row.paid_status.replace("_", " ")
                                  )
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.amount_due"),
                  "sort-as": "due_amount"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("invoices.amount_due")))
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.due_amount,
                                row.user.currency
                              )
                            )
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown no-click"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("invoices.action")))]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator"
                            }),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                attrs: {
                                  to: "invoices/" + row.id + "/edit",
                                  "tag-name": "router-link"
                                }
                              },
                              [
                                _c("pencil-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600"
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.edit")) +
                                    "\n            "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                attrs: {
                                  to: "invoices/" + row.id + "/view",
                                  "tag-name": "router-link"
                                }
                              },
                              [
                                _c("eye-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600"
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("invoices.view")) +
                                    "\n            "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            row.status == "DRAFT"
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.sendInvoice(row)
                                      }
                                    }
                                  },
                                  [
                                    _c("paper-airplane-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600"
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("invoices.send_invoice")
                                        ) +
                                        "\n            "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.status === "SENT" || row.status === "VIEWED"
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.sendInvoice(row)
                                      }
                                    }
                                  },
                                  [
                                    _c("paper-airplane-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600"
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("invoices.resend_invoice")
                                        ) +
                                        "\n            "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.status == "DRAFT"
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.markInvoiceAsSent(row.id)
                                      }
                                    }
                                  },
                                  [
                                    _c("check-circle-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600"
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("invoices.mark_as_sent")
                                        ) +
                                        "\n            "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.status === "SENT" ||
                            row.status === "VIEWED" ||
                            row.status === "OVERDUE"
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      to:
                                        "/admin/payments/" + row.id + "/create",
                                      "tag-name": "router-link"
                                    }
                                  },
                                  [
                                    _c("credit-card-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600"
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("payments.record_payment")
                                        ) +
                                        "\n            "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.onCloneInvoice(row.id)
                                  }
                                }
                              },
                              [
                                _c("document-duplicate-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600"
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("invoices.clone_invoice")) +
                                    "\n            "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.removeInvoice(row.id)
                                  }
                                }
                              },
                              [
                                _c("trash-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600"
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n            "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }