var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid h-full grid-cols-12 overflow-y-hidden bg-gray-100" },
    [
      _c("base-notification"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "relative flex-col items-center justify-center hidden w-full h-screen pl-8 bg-no-repeat bg-cover md:col-span-6 lg:col-span-8 md:flex content-box"
        },
        [
          _c("div", { staticClass: "m-auto " }, [
            _c(
              "h1",
              {
                staticClass:
                  "hidden mb-3 text-3xl font-bold leading-normal text-white xl:text-5xl xl:leading-tight md:none lg:block"
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("layout_login.super_simple_invoicing")) +
                    " "
                ),
                _c("br"),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("layout_login.for_freelancer")) +
                    " "
                ),
                _c("br"),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("layout_login.small_businesses")) +
                    " "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "hidden text-sm not-italic font-normal leading-normal text-gray-100 xl:text-base xl:leading-6 md:none lg:block"
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("layout_login.crater_help"))
                ),
                _c("br"),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("layout_login.invoices_and_estimates"))
                ),
                _c("br")
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "absolute z-50 w-full bg-no-repeat content-bottom"
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "flex items-center justify-center w-full max-w-sm col-span-12 p-4 mx-auto text-gray-900 md:p-8 md:col-span-6 lg:col-span-4 flex-2 md:pb-48 md:pt-40"
        },
        [
          _c(
            "div",
            { staticClass: "w-full" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("router-view"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "pt-24 mt-0 text-sm not-italic font-medium leading-relaxed text-left text-gray-500 md:pt-40"
                },
                [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(_vm._s(_vm.$t("layout_login.copyright_crater")))
                  ])
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/admin" } }, [
      _c("img", {
        staticClass: "block w-48 h-auto max-w-full mb-32 text-primary-400",
        attrs: { src: "/assets/img/logo.png", alt: "Crater Logo" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }