var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-col items-center justify-between w-full h-32 pt-10  step-indicator"
    },
    [
      _c("img", {
        staticClass: "h-12",
        attrs: {
          id: "logo-crater",
          src: "/assets/img/crater-logo.png",
          alt: "Crater Logo"
        }
      }),
      _vm._v(" "),
      _c(
        "sw-wizard",
        {
          attrs: {
            steps: 7,
            "current-step": _vm.step,
            "allow-navigation-redirect": false
          },
          on: {
            "update:currentStep": function($event) {
              _vm.step = $event
            },
            "update:current-step": function($event) {
              _vm.step = $event
            }
          }
        },
        [_c(_vm.tab, { tag: "component", on: { next: _vm.setTab } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }