var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full text-base site-wrapper" },
    [
      _c("base-notification"),
      _vm._v(" "),
      _c("div", { staticClass: "container mx-auto" }, [_c("router-view")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }