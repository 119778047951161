var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isSuperAdmin
    ? _c(
        "base-page",
        { staticClass: "item-create" },
        [
          _c(
            "sw-page-header",
            { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("general.home"),
                      to: "/admin/dashboard"
                    }
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$tc("users.user", 2),
                      to: "/admin/users"
                    }
                  }),
                  _vm._v(" "),
                  _vm.$route.name === "users.edit"
                    ? _c("sw-breadcrumb-item", {
                        attrs: {
                          title: _vm.$t("users.edit_user"),
                          to: "#",
                          active: ""
                        }
                      })
                    : _c("sw-breadcrumb-item", {
                        attrs: {
                          title: _vm.$t("users.new_user"),
                          to: "#",
                          active: ""
                        }
                      })
                ],
                1
              ),
              _vm._v(" "),
              _c("template", { slot: "actions" })
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "grid grid-cols-12" }, [
            _c("div", { staticClass: "col-span-12 md:col-span-8" }, [
              _c(
                "form",
                {
                  attrs: { action: "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitUser.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "sw-card",
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("users.name"),
                            error: _vm.nameError,
                            required: ""
                          }
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.formData.name.$error,
                              focus: "",
                              type: "text",
                              name: "name"
                            },
                            on: {
                              input: function($event) {
                                return _vm.$v.formData.name.$touch()
                              }
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-4",
                          attrs: {
                            label: _vm.$t("users.email"),
                            error: _vm.emailError,
                            required: ""
                          }
                        },
                        [
                          _c("sw-input", {
                            attrs: {
                              invalid: _vm.$v.formData.email.$error,
                              type: "text",
                              name: "email",
                              "tab-index": "3"
                            },
                            on: {
                              input: function($event) {
                                return _vm.$v.formData.email.$touch()
                              }
                            },
                            model: {
                              value: _vm.formData.email,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "email",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.email"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-4",
                          attrs: {
                            label: _vm.$tc("users.password"),
                            error: _vm.passwordError,
                            required: ""
                          }
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.formData.password.$error,
                              type: "password"
                            },
                            on: {
                              input: function($event) {
                                return _vm.$v.formData.password.$touch()
                              }
                            },
                            model: {
                              value: _vm.formData.password,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "password", $$v)
                              },
                              expression: "formData.password"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-4 mb-6",
                          attrs: { label: _vm.$t("users.phone") }
                        },
                        [
                          _c("sw-input", {
                            attrs: {
                              type: "text",
                              name: "phone",
                              "tab-index": "4"
                            },
                            model: {
                              value: _vm.formData.phone,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "phone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.phone"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-6 mb-4" },
                        [
                          _c(
                            "sw-button",
                            {
                              staticClass:
                                "flex justify-center w-full md:w-auto",
                              attrs: {
                                loading: _vm.isLoading,
                                variant: "primary",
                                type: "submit",
                                size: "lg"
                              }
                            },
                            [
                              !_vm.isLoading
                                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                                : _vm._e(),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.isEdit
                                      ? _vm.$t("users.update_user")
                                      : _vm.$t("users.save_user")
                                  ) +
                                  "\n            "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }