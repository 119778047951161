var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submitData.apply(null, arguments)
        }
      }
    },
    [
      _c("div", { staticClass: "px-8 py-6" }, [
        _c(
          "div",
          { staticClass: "grid gap-6 grid-col-1 md:grid-cols-2" },
          [
            _c(
              "sw-input-group",
              {
                attrs: {
                  label: _vm.$t("settings.disk.name"),
                  error: _vm.nameError,
                  required: ""
                }
              },
              [
                _c("sw-input", {
                  staticClass: "mt-2",
                  attrs: {
                    type: "text",
                    name: "name",
                    invalid: _vm.$v.name.$error
                  },
                  on: {
                    input: function($event) {
                      return _vm.$v.name.$touch()
                    }
                  },
                  model: {
                    value: _vm.name,
                    callback: function($$v) {
                      _vm.name = $$v
                    },
                    expression: "name"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                attrs: { label: _vm.$tc("settings.disk.driver"), required: "" }
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    invalid: _vm.$v.selected_disk.$error,
                    options: _vm.disks,
                    searchable: true,
                    "allow-empty": false,
                    "show-labels": false,
                    "track-by": "value",
                    label: "name"
                  },
                  on: { input: _vm.onChangeDriver },
                  model: {
                    value: _vm.selected_disk,
                    callback: function($$v) {
                      _vm.selected_disk = $$v
                    },
                    expression: "selected_disk"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                attrs: {
                  label: _vm.$t("settings.disk.local_root"),
                  required: ""
                }
              },
              [
                _c("sw-input", {
                  staticClass: "mt-2",
                  attrs: {
                    type: "text",
                    name: "name",
                    placeholder: "Ex. /user/root/"
                  },
                  model: {
                    value: _vm.diskConfigData.root,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.diskConfigData,
                        "root",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "diskConfigData.root"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        !_vm.isDisabled
          ? _c("div", { staticClass: "flex items-center mt-6" }, [
              _c(
                "div",
                { staticClass: "relative flex items-center w-12" },
                [
                  _c("sw-switch", {
                    staticClass: "flex",
                    model: {
                      value: _vm.set_as_default,
                      callback: function($$v) {
                        _vm.set_as_default = $$v
                      },
                      expression: "set_as_default"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-4 right" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "p-0 mb-1 text-base leading-snug text-black box-title"
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("settings.disk.is_default")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._t("default", null, {
        diskData: { isLoading: _vm.isLoading, submitData: _vm.submitData }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }