var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-field-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitCustomFieldData.apply(null, arguments)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "px-8 py-8 overflow-y-auto sw-scroll sm:p-6",
            staticStyle: { "max-height": "600px" }
          },
          [
            _c(
              "sw-input-group",
              {
                attrs: {
                  label: _vm.$t("settings.custom_fields.name"),
                  error: _vm.nameError,
                  horizontal: "",
                  required: ""
                }
              },
              [
                _c("sw-input", {
                  ref: "name",
                  attrs: { invalid: _vm.$v.formData.name.$error, type: "text" },
                  on: {
                    input: function($event) {
                      return _vm.$v.formData.name.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-5",
                attrs: {
                  label: _vm.$t("settings.custom_fields.model"),
                  error: _vm.modalTypeError,
                  horizontal: "",
                  required: ""
                }
              },
              [
                _c("sw-select", {
                  attrs: {
                    options: _vm.modelTypes,
                    invalid: _vm.$v.formData.model_type.$error,
                    searchable: true,
                    "show-labels": false,
                    "allow-empty": false
                  },
                  on: {
                    input: function($event) {
                      return _vm.$v.formData.model_type.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.model_type,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "model_type", $$v)
                    },
                    expression: "formData.model_type"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-5",
                attrs: {
                  label: _vm.$t("settings.custom_fields.required"),
                  horizontal: ""
                }
              },
              [
                _c("sw-switch", {
                  staticStyle: { "margin-top": "-20px" },
                  model: {
                    value: _vm.formData.is_required,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "is_required", $$v)
                    },
                    expression: "formData.is_required"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-5",
                attrs: {
                  label: _vm.$t("settings.custom_fields.type"),
                  error: _vm.dataTypeError,
                  horizontal: "",
                  required: ""
                }
              },
              [
                _c("sw-select", {
                  attrs: {
                    options: _vm.dataTypes,
                    invalid: _vm.$v.selectType.$error,
                    searchable: true,
                    "show-labels": false,
                    "allow-empty": false,
                    "track-by": "label",
                    label: "label"
                  },
                  on: { input: _vm.onSelectTypeChange },
                  model: {
                    value: _vm.selectType,
                    callback: function($$v) {
                      _vm.selectType = $$v
                    },
                    expression: "selectType"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-5",
                attrs: {
                  label: _vm.$t("settings.custom_fields.label"),
                  error: _vm.labelError,
                  horizontal: "",
                  required: ""
                }
              },
              [
                _c("sw-input", {
                  ref: "name",
                  attrs: {
                    invalid: _vm.$v.formData.label.$error,
                    type: "text"
                  },
                  on: {
                    input: function($event) {
                      return _vm.$v.formData.label.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.label,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "label", $$v)
                    },
                    expression: "formData.label"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.isDropdownSelected
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-5",
                    attrs: {
                      label: _vm.$t("settings.custom_fields.options"),
                      horizontal: ""
                    }
                  },
                  [
                    _c("option-create", { on: { onAdd: _vm.addNewOptions } }),
                    _vm._v(" "),
                    _vm._l(_vm.formData.options, function(option, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "flex items-center",
                          staticStyle: { "margin-top": "5px" }
                        },
                        [
                          _c("sw-input", {
                            staticStyle: { width: "90%" },
                            attrs: { type: "text" },
                            model: {
                              value: option.name,
                              callback: function($$v) {
                                _vm.$set(option, "name", $$v)
                              },
                              expression: "option.name"
                            }
                          }),
                          _vm._v(" "),
                          _c("minus-circle-icon", {
                            staticClass: "ml-1 cursor-pointer icon text-danger",
                            on: {
                              click: function($event) {
                                return _vm.removeOption(index)
                              }
                            }
                          })
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.formData.type
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "relative mt-5",
                    attrs: {
                      label: _vm.$t("settings.custom_fields.default_value"),
                      horizontal: ""
                    }
                  },
                  [
                    _c(_vm.formData.type + "Type", {
                      tag: "component",
                      attrs: {
                        value: _vm.formData.default_answer,
                        options: _vm.formData.options,
                        "default-date-time": _vm.formData.dateTimeValue
                      },
                      model: {
                        value: _vm.formData.default_answer,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "default_answer", $$v)
                        },
                        expression: "formData.default_answer"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isSwitchTypeSelected
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-5",
                    attrs: {
                      label: _vm.$t("settings.custom_fields.placeholder"),
                      horizontal: ""
                    }
                  },
                  [
                    _c("sw-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.formData.placeholder,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "placeholder", $$v)
                        },
                        expression: "formData.placeholder"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-5",
                attrs: {
                  label: _vm.$t("settings.custom_fields.order"),
                  error: _vm.orderError,
                  horizontal: ""
                }
              },
              [
                _c("sw-input", {
                  attrs: {
                    invalid: _vm.$v.formData.order.$error,
                    type: "number"
                  },
                  on: {
                    input: function($event) {
                      return _vm.$v.formData.order.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.order,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "order", $$v)
                    },
                    expression: "formData.order"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-0 flex justify-end p-4 border-t border-solid border-gray-light border-modal-bg"
          },
          [
            _c(
              "sw-button",
              {
                staticClass: "mr-3",
                attrs: { type: "button", variant: "primary-outline" },
                on: { click: _vm.closeCategoryModal }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary",
                  type: "submit"
                }
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(
                      !_vm.isEdit
                        ? _vm.$t("general.save")
                        : _vm.$t("general.update")
                    ) +
                    "\n      "
                )
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }