var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "note-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitNote.apply(null, arguments)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "px-8 py-8 sm:p-6" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$t("settings.customization.notes.name"),
                  error: _vm.nameError,
                  variant: "vertical",
                  required: ""
                }
              },
              [
                _c("sw-input", {
                  ref: "name",
                  attrs: { invalid: _vm.$v.formData.name.$error, type: "text" },
                  on: {
                    input: function($event) {
                      return _vm.$v.formData.name.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$t("settings.customization.notes.type"),
                  error: _vm.typeError,
                  variant: "vertical",
                  required: ""
                }
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.types,
                    "allow-empty": false,
                    "show-labels": false
                  },
                  model: {
                    value: _vm.noteType,
                    callback: function($$v) {
                      _vm.noteType = $$v
                    },
                    expression: "noteType"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                attrs: {
                  label: _vm.$t("settings.customization.notes.notes"),
                  error: _vm.noteError,
                  variant: "vertical",
                  required: ""
                }
              },
              [
                _c("base-custom-input", {
                  staticClass: "mt-2",
                  attrs: { fields: _vm.fields },
                  model: {
                    value: _vm.formData.notes,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "notes", $$v)
                    },
                    expression: "formData.notes"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-0 flex justify-end px-4 py-4 border-t border-solid border-gray-light"
          },
          [
            _c(
              "sw-button",
              {
                staticClass: "mr-2",
                attrs: { variant: "primary-outline", type: "button" },
                on: { click: _vm.closeNoteModal }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                attrs: {
                  loading: _vm.isLoading,
                  variant: "primary",
                  icon: "save",
                  type: "submit"
                }
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(
                      !_vm.isEdit
                        ? _vm.$t("general.save")
                        : _vm.$t("general.update")
                    ) +
                    "\n      "
                )
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }