var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-disk-modal" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitData.apply(null, arguments)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "px-8 py-6" },
          [
            _c(
              "sw-input-group",
              {
                attrs: { label: _vm.$t("settings.disk.driver"), required: "" }
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.getDisks,
                    searchable: true,
                    "allow-empty": false,
                    "show-labels": false,
                    "custom-label": _vm.getCustomLabel,
                    "track-by": "id"
                  },
                  model: {
                    value: _vm.selected_disk,
                    callback: function($$v) {
                      _vm.selected_disk = $$v
                    },
                    expression: "selected_disk"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-0 flex justify-end p-4 border-t border-solid border-gray-light"
          },
          [
            _c(
              "sw-button",
              {
                staticClass: "mr-3 text-sm",
                attrs: { type: "button", variant: "primary-outline" },
                on: { click: _vm.closeDisk }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass: "text-sm",
                attrs: {
                  loading: _vm.isLoading,
                  icon: "save",
                  type: "submit",
                  variant: "primary"
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.save")) + "\n      "
                )
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }