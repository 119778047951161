var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.estimate
    ? _c(
        "base-page",
        { staticClass: "xl:pl-96" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.pageTitle } },
            [
              _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "div",
                    { staticClass: "mr-3 text-sm" },
                    [
                      _vm.estimate.status === "DRAFT"
                        ? _c(
                            "sw-button",
                            {
                              attrs: {
                                disabled: _vm.isMarkAsSent,
                                variant: "primary-outline"
                              },
                              on: { click: _vm.onMarkAsSent }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("estimates.mark_as_sent")) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.estimate.status === "DRAFT"
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "text-sm",
                          attrs: {
                            disabled: _vm.isSendingEmail,
                            variant: "primary"
                          },
                          on: { click: _vm.onSendEstimate }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("estimates.send_estimate")) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown",
                    { staticClass: "ml-3" },
                    [
                      _c(
                        "sw-button",
                        {
                          attrs: { slot: "activator", variant: "primary" },
                          slot: "activator"
                        },
                        [_c("dots-horizontal-icon", { staticClass: "h-5" })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-dropdown-item",
                        { on: { click: _vm.copyPdfUrl } },
                        [
                          _c("link-icon", {
                            staticClass: "h-5 mr-3 text-primary-800"
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.copy_pdf_url")) +
                              "\n        "
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            to:
                              "/admin/estimates/" +
                              _vm.$route.params.id +
                              "/edit",
                            "tag-name": "router-link"
                          }
                        },
                        [
                          _c("pencil-icon", {
                            staticClass: "h-5 mr-3 text-primary-800"
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.edit")) +
                              "\n        "
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.removeEstimate(_vm.$route.params.id)
                            }
                          }
                        },
                        [
                          _c("trash-icon", {
                            staticClass: "h-5 mr-3 text-primary-800"
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.delete")) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "fixed top-0 left-0 hidden h-full pt-16 pb-4 ml-56 bg-white xl:ml-64 w-88 xl:block"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex items-center justify-between px-4 pt-8 pb-2 border border-gray-200 border-solid height-full"
                },
                [
                  _c(
                    "sw-input",
                    {
                      staticClass: "mb-6",
                      attrs: {
                        placeholder: _vm.$t("general.search"),
                        type: "text",
                        variant: "gray"
                      },
                      on: {
                        input: function($event) {
                          return _vm.onSearched()
                        }
                      },
                      model: {
                        value: _vm.searchData.searchText,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "searchText", $$v)
                        },
                        expression: "searchData.searchText"
                      }
                    },
                    [
                      _c("search-icon", {
                        staticClass: "h-5",
                        attrs: { slot: "rightIcon" },
                        slot: "rightIcon"
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex mb-6 ml-3",
                      attrs: { role: "group", "aria-label": "First group" }
                    },
                    [
                      _c(
                        "sw-dropdown",
                        {
                          staticClass: "ml-3",
                          attrs: { position: "bottom-start" }
                        },
                        [
                          _c(
                            "sw-button",
                            {
                              attrs: {
                                slot: "activator",
                                size: "md",
                                variant: "gray-light"
                              },
                              slot: "activator"
                            },
                            [_c("filter-icon", { staticClass: "h-5" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "px-2 py-1 pb-2 mb-1 mb-2 text-sm border-b border-gray-200 border-solid"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.sort_by")) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            { staticClass: "flex px-1 py-2 cursor-pointer" },
                            [
                              _c(
                                "sw-input-group",
                                { staticClass: "-mt-3 font-normal" },
                                [
                                  _c("sw-radio", {
                                    attrs: {
                                      id: "filter_estimate_date",
                                      label: _vm.$t(
                                        "reports.estimates.estimate_date"
                                      ),
                                      size: "sm",
                                      name: "filter",
                                      value: "estimate_date"
                                    },
                                    on: { change: _vm.onSearched },
                                    model: {
                                      value: _vm.searchData.orderByField,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchData,
                                          "orderByField",
                                          $$v
                                        )
                                      },
                                      expression: "searchData.orderByField"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            { staticClass: "flex px-1 py-2 cursor-pointer" },
                            [
                              _c(
                                "sw-input-group",
                                { staticClass: "-mt-3 font-normal" },
                                [
                                  _c("sw-radio", {
                                    attrs: {
                                      id: "filter_due_date",
                                      label: _vm.$t("estimates.due_date"),
                                      value: "expiry_date",
                                      size: "sm",
                                      name: "filter"
                                    },
                                    on: { change: _vm.onSearched },
                                    model: {
                                      value: _vm.searchData.orderByField,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchData,
                                          "orderByField",
                                          $$v
                                        )
                                      },
                                      expression: "searchData.orderByField"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            { staticClass: "flex px-1 py-2 cursor-pointer" },
                            [
                              _c(
                                "sw-input-group",
                                { staticClass: "-mt-3 font-normal" },
                                [
                                  _c("sw-radio", {
                                    attrs: {
                                      id: "filter_estimate_number",
                                      label: _vm.$t(
                                        "estimates.estimate_number"
                                      ),
                                      value: "estimate_number",
                                      size: "sm",
                                      name: "filter"
                                    },
                                    on: { change: _vm.onSearched },
                                    model: {
                                      value: _vm.searchData.orderByField,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchData,
                                          "orderByField",
                                          $$v
                                        )
                                      },
                                      expression: "searchData.orderByField"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.top-center",
                              value: { content: _vm.getOrderName },
                              expression: "{ content: getOrderName }",
                              modifiers: { "top-center": true }
                            }
                          ],
                          staticClass: "ml-1",
                          attrs: { size: "md", variant: "gray-light" },
                          on: { click: _vm.sortData }
                        },
                        [
                          _vm.getOrderBy
                            ? _c("sort-ascending-icon", { staticClass: "h-5" })
                            : _c("sort-descending-icon", { staticClass: "h-5" })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.isSearching
                ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
                : _c(
                    "div",
                    {
                      staticClass:
                        "h-full pb-32 overflow-y-scroll border-l border-gray-200 border-solid sw-scroll"
                    },
                    [
                      _vm._l(_vm.estimates, function(estimate, index) {
                        return _c(
                          "router-link",
                          {
                            key: index,
                            class: [
                              "flex justify-between side-estimate p-4 cursor-pointer hover:bg-gray-100 items-center border-l-4 border-transparent",
                              {
                                "bg-gray-100 border-l-4 border-primary-500 border-solid": _vm.hasActiveUrl(
                                  estimate.id
                                )
                              }
                            ],
                            staticStyle: {
                              "border-bottom":
                                "1px solid rgba(185, 193, 209, 0.41)"
                            },
                            attrs: {
                              to: "/admin/estimates/" + estimate.id + "/view",
                              id: "estimate-" + estimate.id
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex-2" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pr-2 mb-2 text-sm not-italic font-normal leading-5 text-black capitalize truncate"
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(estimate.user.name) +
                                        "\n          "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mt-1 mb-2 text-xs not-italic font-medium leading-5 text-gray-600"
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(estimate.estimate_number) +
                                        "\n          "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-1 text-xs",
                                    attrs: {
                                      "bg-color": _vm.$utils.getBadgeStatusColor(
                                        estimate.status
                                      ).bgColor,
                                      color: _vm.$utils.getBadgeStatusColor(
                                        estimate.status
                                      ).color
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$utils.getStatusTranslation(
                                            estimate.status
                                          )
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex-1 whitespace-nowrap right" },
                              [
                                _c("div", {
                                  staticClass:
                                    "mb-2 text-xl not-italic font-semibold leading-8 text-right text-gray-900",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        estimate.total,
                                        estimate.user.currency
                                      )
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-sm not-italic font-normal leading-5 text-right text-gray-600 est-date"
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(estimate.formattedEstimateDate) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      _vm._v(" "),
                      !_vm.estimates.length
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "flex justify-center px-4 mt-5 text-sm text-gray-600"
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(
                                    _vm.$t("estimates.no_matching_estimates")
                                  ) +
                                  "\n      "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "flex flex-col min-h-0 mt-8 overflow-hidden sw-scroll",
              staticStyle: { height: "75vh" }
            },
            [
              _c("iframe", {
                staticClass:
                  "flex-1 border border-gray-400 border-solid rounded-md frame-style",
                attrs: { src: "" + _vm.shareableLink }
              })
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }